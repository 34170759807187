import React from 'react';
import { LandingSearchForm } from '../../../../components';
import { isOriginInUse } from '../../../../util/search';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../../util/routes';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import { useConfiguration } from '../../../../context/configurationContext';

import css from './SectionHero.module.css';
import routeConfiguration from '../../../../routing/routeConfiguration';

const { LatLng, LatLngBounds } = sdkTypes;
function RenderLandingPageSectionHero() {
  const history = useHistory();

  const handleSubmit = values => {
    const { keywords = null, location = null } = values || {};

    // Initialize searchQuery with potentially present, non-null values
    const searchQuery = {};
    if (keywords) searchQuery.keywords = keywords;
    const { search = 'United States', selectedPlace } = location || {};

    const defaultBounds = new LatLngBounds(
      new LatLng(62.198642531, -81.98187937),
      new LatLng(12.40936803, -125.48773874)
    );

    const { bounds = defaultBounds } = selectedPlace || {};

    Object.assign(searchQuery, { address: search, bounds });

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchQuery));
  };
  return <LandingSearchForm rootClassName={css.landingSearchForm} onSubmit={handleSubmit} />;
}

export default RenderLandingPageSectionHero;
