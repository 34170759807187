import React from 'react';
import Icons from '../../../../components/Icons/Icons';
import { Button } from '../../../../components';
import { useIntl } from '../../../../util/reactIntl';

import css from './SectionColumns.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../../../ducks/auth.duck';

function SectionWhoWeAre() {
  const intl = useIntl();
  const history = useHistory();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const onRedirectToPage = page => {
    history.push(createResourceLocatorString(page, routeConfiguration(), {}, {}));
  };

  return (
    <div className={css.whoAreYouSec}>
      <h2 className={css.sectionTitle}>
        {intl.formatMessage({ id: 'SectionWhoWeAre.whoAreYou' })}
      </h2>
      <div className={css.whoAreYouContent}>
        <div className={css.whoAreYouContentBlock}>
          <Icons name="handShakeIcon" />
          <h3>{intl.formatMessage({ id: 'SectionWhoWeAre.forRenters' })}</h3>
          <p>{intl.formatMessage({ id: 'SectionWhoWeAre.findTrailerDescription' })}</p>
          <Button role="button" onClick={() => onRedirectToPage('SearchPage')}>
            {intl.formatMessage({ id: 'SectionWhoWeAre.findTrailer' })}
          </Button>
        </div>
        <div className={css.whoAreYouContentBlock}>
          <Icons name="moneySecurity" />
          <h3>{intl.formatMessage({ id: 'SectionWhoWeAre.forOwners' })} </h3>
          <p>{intl.formatMessage({ id: 'SectionWhoWeAre.forOwnersDescription' })}</p>
          <Button
            role="button"
            onClick={() => onRedirectToPage(isAuthenticated ? 'NewListingPage' : 'SignupPage')}
          >
            {intl.formatMessage({ id: 'SectionWhoWeAre.rentYourTrailer' })}{' '}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SectionWhoWeAre;
